import React from 'react';
import Modal from '@cimpress/react-components/lib/Modal';
import { Robot } from '@cimpress/react-components/lib/shapes/index';
import { MCP_SUPPORT_PIM_SLACK_CHANNEL_LINK } from '../../constants/retirementModel';

export function RetirementModal() {
  return (
    <Modal show={true} bsStyle="warning" bsSize="lg">
      <div
        style={{
          'align-items': 'center',
          display: 'flex',
          'flex-direction': 'column'
        }}>
        <Robot bsStyle="warning" alternate style={{ margin: 'auto !important' }} />
        Contract Manager V2 has been retired. For any questions or assistance, please reach out in the Slack channel
        <b>
          <a href={MCP_SUPPORT_PIM_SLACK_CHANNEL_LINK} target="_blank" rel="noopener noreferrer">
            {' '}
            #mcp-support-pim.
          </a>
        </b>
      </div>
    </Modal>
  );
}
